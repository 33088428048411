import { colorProgressbarLine } from "./helpers";
import Progressbar from "../../hoc/progressbar";

export default function LearnInfo({ selectedTheme, blocksDone, blocks }) {
  return (
    <div className="learn__top">
      <div className="learn-block">
        <p className="learn__bold">ТЕМА {selectedTheme?.number}</p>
        <p className="learn__bold">
          {blocks && `${blocksDone?.length}/${blocks?.length}`}
        </p>
      </div>
      <div className="learn-block">
        <h1 className="learn__text">{selectedTheme?.title}</h1>
        <p className="learn__text">{blocks && "уроків завершено"}</p>
      </div>
      <div className="learn__progressbar">
        <Progressbar
          proccesNum={selectedTheme?.progress}
          colorProcces={colorProgressbarLine(selectedTheme)}
        />
      </div>
    </div>
  );
}
