import { useEffect, useRef } from "react";
import { forms, signsNumber } from "./data";
import RoadSign from "./RoadSign";

export default function RoadBlock({
  block,
  blocks,
  idx,
  onGetRetestQuestion,
  onGetBlockQuestions,
  mapClass,
  selectedTheme,
  token,
  currentTheme,
  onCurrentBlock,
  setAllowOpenModal,
  containerRef,
}) {
  const blockRef = useRef(null);
  const container = containerRef?.current;
  // ---------------- GET BLOCK QUESTIONS ------------------------
  const getCurrentBlock = (e) => {
    const currentBlockIdx = e.target.closest(".sign").dataset.index;
    const status = e.target.closest(".sign").dataset.status;

    if (status === "error") {
      onGetRetestQuestion({
        id: currentTheme,
        token: token,
        idx: currentBlockIdx,
      });
      sessionStorage.setItem(
        "general",
        JSON.stringify({
          type: "retest",
          theme: currentTheme,
          block: currentBlockIdx,
        })
      );
      localStorage.setItem("currentThemeId", currentTheme);
    } else {
      onGetBlockQuestions({
        id: currentTheme,
        token: token,
        idx: currentBlockIdx,
      });
      sessionStorage.setItem(
        "general",
        JSON.stringify({
          type: "theme",
          theme: currentTheme,
          block: currentBlockIdx,
        })
      );
      localStorage.setItem("currentThemeId", currentTheme);
    }

    onCurrentBlock(currentBlockIdx);
    sessionStorage.setItem("currentBlockIdx", currentBlockIdx);
    setAllowOpenModal(false);
  };

  // ---------------- SET ROAD FORM SIGNS ------------------------
  const formSign = forms[selectedTheme?.id % 8];

  const classNum = signsNumber[idx];

  const doneBlocks = blocks?.filter(
    (block) => block.errors === 0 && block.success > 0
  );
  const doneBlocksIdx = blocks?.findIndex(
    (block, idx) => idx === doneBlocks.length - 1
  );
  const currentBlock =
    blocks.length >= doneBlocksIdx + 1 ? doneBlocksIdx + 1 : false;

  const disabled =
    idx !== 0 &&
    idx !== currentBlock &&
    block.errors === 0 &&
    block.success === 0;
  const done = block.errors === 0 && block.success > 0;
  const error = block.errors > 0;
  const current =
    (idx === 0 || idx === currentBlock) &&
    block.errors === 0 &&
    block.success === 0;

  const status = done
    ? "done"
    : "" || error
    ? "error"
    : "" || current
    ? "current"
    : "current";
  const mapDesktop = mapClass ? mapClass : "";
  const signClass = formSign ? `sign--${formSign}` : "";
  const numberClass = classNum ? `sign--${classNum}` : "";
  const pulsCurrClass = current || error ? "puls" : "";
  const classes = [mapDesktop, signClass, numberClass, pulsCurrClass]
    .filter(Boolean)
    .join(" ");

  useEffect(() => {
    let timer = setTimeout(() => {
      if (currentBlock && currentBlock > 3) {
        if (blockRef && window.innerWidth <= 768) {
          const block = blockRef.current;
          if (block) {
            block.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
          }
        }

        if (
          blockRef &&
          container &&
          blocks.length > 8 &&
          window.innerWidth > 768
        ) {
          if (container && blockRef.current) {
            const block = blockRef.current;
            const scrollLeft = block.offsetLeft - container.offsetLeft - 400;
            container.scrollTo({
              left: scrollLeft,
              behavior: "smooth",
            });
          }
        }
      }
    }, 300);

    return () => {
      if (container) {
        container.scrollTo({
          left: blockRef.current,
        });
      }
      clearTimeout(timer);
    };

    // eslint-disable-next-line
  }, [selectedTheme]);

  if (blocks) {
    if (!disabled) {
      return (
        <div
          className={`sign ${classes}`}
          data-index={idx}
          data-status={status}
          onClick={getCurrentBlock}
          ref={blockRef}
        >
          <RoadSign idx={idx} formSign={formSign} status={status} />
        </div>
      );
    }
    if (disabled) {
      return (
        <div className={`sign sign--locked ${classes}`}>
          <RoadSign idx={idx} formSign={formSign} />
        </div>
      );
    }
  }
  return <></>;
}
