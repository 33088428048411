import React from "react";
import { colorClassCards, colorProgressbarLine } from "./helpers";
import Progressbar from "../../hoc/progressbar";
import IconCard from "./IconCard";

export default function CardMobile({
  theme,
  getCurrenTheme,
  currentTheme,
  nextThemeId,
  idx,
}) {
  return (
    <div
      key={theme.id}
      onClick={getCurrenTheme}
      data-id={theme.id}
      data-number={idx + 1}
      className={`card card-mobile ${colorClassCards(
        theme,
        nextThemeId,
        currentTheme
      )}`}
      data-status={theme.disabled || theme.blocked ? "close" : "open"}
    >
      <div className="card-mobile__base">
        <IconCard theme={theme} />
        <div className="card-mobile__desc">
          <div className="card-mobile__title">Тема {theme.number}</div>
          <p className="card-mobile__theme">{theme.title}</p>
        </div>
      </div>
      <Progressbar
        otherClass="card-mobile__progressbar"
        colorProcces={colorProgressbarLine(theme)}
        proccesNum={theme.progress}
      />
    </div>
  );
}
