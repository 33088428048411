import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import Header from "../../hoc/header";
import Footer from "../../hoc/footer";
import ModalPay from "../../hoc/modalPay";

import Plug from "../../common/Plugs/Plug";
import ModalResults from "../../common/Modal/ModalResults";

import Map from "./Map";
import Card from "./Card";
import MapMobile from "./MapMobile";
import CardMobile from "./CardMobile";
import LearnInfo from "./LearnInfo";

import Slider from "react-slick";

import "react-circular-progressbar/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/style/main/app.scss";

const Learn = ({
  user,
  blocks,
  themes,
  currentTheme,
  currentBlock,
  empty,
  onGetThemes,
  onCurrentTheme,
  onGetBlocks,
  onGetBlockQuestions,
  onCurrentBlock,
  onSetEmptyData,
  onGetQuestions,
  token,
  questions,
  failedTheme,
}) => {
  const refSlider = useRef(null);
  // ---------------- SET HISTORY ------------------------
  const history = useHistory();
  // ---------------- SET STATE ------------------------
  const [modalResults, setModalResults] = useState(false);
  const [modalPay, setModalPay] = useState(false);
  const [allowOpenModal, setAllowOpenModal] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [correctCount, setCorrectCount] = useState(0);
  const [blocksDone, setBlocksDone] = useState([]);
  const [disableNextTheme, setDisableNextTheme] = useState(false);
  const [currentThemeId, setCurrentThemeId] = useState(
    localStorage.getItem("currentThemeId")
  );

  // ------------------ GET DATA FROM SESSION STOTAGE ----------------------
  const general = JSON.parse(sessionStorage.getItem("general"));
  const answers = JSON.parse(sessionStorage.getItem("answers"));
  const currentBlockIdx = sessionStorage.getItem("currentBlockIdx");
  const restart = localStorage.getItem("restart");

  // ---------------- GET DONE AND CURRENT THEME ------------------------
  const doneThemes = themes?.filter(
    (theme) => theme.error === 0 && theme.success > 0 && theme.progress === 100
  );
  const doneThemesAndExams = themes?.filter(
    (theme) =>
      theme.error === 0 &&
      theme.success > 0 &&
      theme.progress === 100 &&
      theme.progress_car.progress === 100
  );
  const doneThemesIdx = themes?.findIndex(
    (theme, idx) => idx === doneThemes?.length - 1
  );
  const nextThemeIdx =
    themes?.length >= doneThemesIdx ? doneThemesIdx + 1 : false;
  const nextThemeObj = themes?.find((theme, idx) => idx === nextThemeIdx);
  const nextThemeId = nextThemeObj?.id;

  useEffect(() => {
    if (
      selectedTheme?.progress_car?.progress >= 50 &&
      currentThemeId &&
      general?.type === "all_theme" &&
      !disableNextTheme &&
      allowOpenModal
    ) {
      onCurrentTheme(Number(currentThemeId) + 1);
      setCurrentThemeId(Number(currentThemeId) + 1);
      localStorage.setItem("currentThemeId", Number(currentThemeId) + 1);
      sessionStorage.removeItem("general");
    }

    if (currentThemeId && general?.type !== "all_theme") {
      onCurrentTheme(currentThemeId);
    }
    // eslint-disable-next-line
  }, [
    currentThemeId,
    general,
    allowOpenModal,
    disableNextTheme,
    selectedTheme,
  ]);

  // ---------------- GET CURRENT THEME AND BLOCK ------------------------
  useEffect(() => {
    if (!currentTheme && selectedTheme) {
      onCurrentTheme(Number(selectedTheme?.id));
    }
    onCurrentBlock(sessionStorage.getItem("currentBlockIdx") || 0);
    // eslint-disable-next-line
  }, [selectedTheme, themes]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (token || (token && !themes)) {
        onGetThemes(token);
      }
    }, 300);
    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [token]);

  // ---------------- SET SELECTED THEME ------------------------
  useEffect(() => {
    if (themes) {
      if (currentThemeId) {
        const selectedTheme = themes.find(
          (theme) => Number(currentThemeId) === theme.id
        );
        setSelectedTheme(selectedTheme);
      }

      if (!currentThemeId) {
        const themeId = nextThemeId - 1 === 0 ? 1 : nextThemeId - 1;
        const selectedTheme = themes.find((theme) => themeId === theme.id);
        onCurrentTheme(selectedTheme?.id);
        setSelectedTheme(selectedTheme);
      }
    }
    // eslint-disable-next-line
  }, [themes, currentThemeId, nextThemeId]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (currentTheme && token) {
        onGetBlocks({ id: Math.max(Number(currentTheme), 1), token: token });
      }
    }, 300);
    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [currentTheme, token]);

  // ---------------- GET QUESTIONS AFTER 404 ERROR ------------------------
  useEffect(() => {
    if (empty) {
      onGetBlockQuestions({
        id: currentTheme,
        token: token,
        idx: currentBlockIdx,
      });
      sessionStorage.setItem(
        "general",
        JSON.stringify({
          type: "theme",
          theme: currentTheme,
          block: currentBlock,
        })
      );
      onSetEmptyData(false);
    }
    // eslint-disable-next-line
  }, [empty, token]);

  // ---------------- SET PREMIUM MODAL ------------------------
  useEffect(() => {
    if (!user.premium_check && selectedTheme && allowOpenModal) {
      if (
        (Number(currentTheme) === 1 &&
          selectedTheme.progress === 100 &&
          selectedTheme.progress_car?.progress >= 50) ||
        (Number(currentTheme) === 2 &&
          selectedTheme.progress === 100 &&
          selectedTheme.progress_car?.progress >= 50)
      ) {
        setModalPay(true);
        setAllowOpenModal(false);
      }
    }
    // eslint-disable-next-line
  }, [currentTheme, selectedTheme, allowOpenModal]);

  // ---------------- SET MODAL FOR RESULTS ------------------------
  useEffect(() => {
    const types = ["theme", "retest", "all_theme"];
    if (types.includes(general?.type) && answers) {
      setModalResults(true);
      document.body.classList.add("open");
    } else {
      setModalResults(false);
      document.body.classList.remove("open");
    }
    // eslint-disable-next-line
  }, []);

  // ---------------- SCROLL FOR CURRENT THEME FOR MOBILE ------------------------
  useEffect(() => {
    if (selectedTheme) {
      const themeCount = themes.findIndex(
        (theme) => theme.id === Number(selectedTheme.id)
      );

      const el = document.querySelector(
        `.card-mobile[data-number="${themeCount + 1}"]`
      );

      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
    // eslint-disable-next-line
  }, [selectedTheme]);

  // ---------------- SET FINISH PAGE ------------------------
  useEffect(() => {
    if (themes && !restart) {
      if (
        doneThemesAndExams?.length === themes?.length &&
        selectedTheme?.progress_car?.progress >= 50
      ) {
        history.push("/finish");
        localStorage.setItem("restart", true);
      }
    }
    // eslint-disable-next-line
  }, [doneThemesAndExams, themes]);

  // ---------------- GET CORRECT ANSWERS ------------------------
  useEffect(() => {
    if (answers) {
      const correctCount = answers?.filter(
        (answer) => answer.correct === 1
      )?.length;
      setCorrectCount(correctCount);
    }
    // eslint-disable-next-line
  }, [answers]);

  // ---------------- GET DONE THEME AND BLOCKS ------------------------
  useEffect(() => {
    if (blocks) {
      const blocksDone = blocks.filter(
        (block) => block.errors === 0 && block.success > 0
      );
      setBlocksDone(blocksDone);
    }
    // eslint-disable-next-line
  }, [blocks]);

  useEffect(() => {
    if (
      nextThemeObj?.blocked ||
      nextThemeObj?.disable ||
      (nextThemeObj?.disable && nextThemeObj?.blocked)
    ) {
      setDisableNextTheme(true);
    }
    // eslint-disable-next-line
  }, [nextThemeObj]);

  useEffect(() => {
    if (questions?.length > 0) {
      history.push("/test");
      sessionStorage.removeItem("result");
      sessionStorage.removeItem("answers");
    }
    // eslint-disable-next-line
  }, [questions]);

  // ---------------- GET CURRENT THEME ------------------------
  // ---------------- GET CURRENT BLOCKS ------------------------
  const getCurrenTheme = (e) => {
    const theme = e.target.closest(".card");
    const themeId = theme.dataset.id;
    const status = theme.dataset.status;

    if (status === "open") {
      setCurrentThemeId(Number(themeId));
      localStorage.setItem("currentThemeId", themeId);
      sessionStorage.setItem("general", JSON.stringify({ theme: themeId }));
    }

    if (e.target.closest(".card--locked")) {
      setModalPay(true);
    }

    setAllowOpenModal(false);
  };

  // ---------------- SET SLIDER FOR CARDS ------------------------
  const settingsCards = {
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      if (selectedTheme && refSlider.current) {
        const themeCount = themes.findIndex(
          (theme) => theme.id === Number(selectedTheme.id)
        );
        refSlider.current.slickGoTo(themeCount);
      }
    }, 300);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [selectedTheme]);

  return (
    <div className="main">
      {modalResults && (
        <ModalResults
          setModalResults={setModalResults}
          setAllowOpenModal={setAllowOpenModal}
          correctCount={correctCount}
          themes={themes}
          user={user}
          currentTheme={currentTheme}
          token={token}
          onGetQuestions={onGetQuestions}
          failedTheme={failedTheme}
        />
      )}
      {modalPay && <ModalPay setModalPay={setModalPay} />}
      <Header />
      <div className="learn">
        <div className="learn__container container">
          {!token ? (
            <div className="learn__plug">
              <Plug plugText="Увійдіть, щоб почати тестування!" />
            </div>
          ) : (
            <>
              <div className="learn__desc">
                <LearnInfo
                  selectedTheme={selectedTheme}
                  blocksDone={blocksDone}
                  blocks={blocks}
                />
                <div className="learn__map">
                  {selectedTheme && (
                    <Map
                      blocks={blocks}
                      selectedTheme={selectedTheme}
                      blocksDone={blocksDone}
                      setAllowOpenModal={setAllowOpenModal}
                    />
                  )}
                </div>
                <div className="learn__cards">
                  <Slider ref={refSlider} {...settingsCards}>
                    {themes?.map((theme, idx) => {
                      return (
                        <div key={theme.id}>
                          <Card
                            theme={theme}
                            nextThemeId={nextThemeId}
                            currentTheme={currentTheme}
                            selectedTheme={selectedTheme}
                            getCurrenTheme={getCurrenTheme}
                            idx={idx}
                          />
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>

              <div className="learn__mobile">
                <div className="learn__cards">
                  {themes?.map((theme, idx) => (
                    <div className="learn__card" key={theme.id}>
                      <CardMobile
                        theme={theme}
                        getCurrenTheme={getCurrenTheme}
                        idx={idx}
                        nextThemeId={nextThemeId}
                        currentTheme={currentTheme}
                      />
                      {selectedTheme && selectedTheme?.id === theme.id && (
                        <div className="learn__map" key={theme.id}>
                          <MapMobile
                            blocks={blocks}
                            selectedTheme={selectedTheme}
                            setAllowOpenModal={setAllowOpenModal}
                            blocksDone={blocksDone}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="main__footer">
        <Footer />
      </div>
    </div>
  );
};

export default Learn;
